<template>
  <div class="setting">
    <van-cell to="/accountBind" center is-link size="large">
      <template #title>
        <span class="custom-title">账号绑定</span>
      </template>
    </van-cell>
    <van-cell to="/modifyPassword" center is-link size="large">
      <template #title>
        <span class="custom-title">修改密码</span>
      </template>
    </van-cell>
    <van-cell @click="showSetting" class="radar" center is-link size="large">
      <template #title>
        <span class="custom-title">雷达设置</span>
      </template>
    </van-cell>
  </div>
</template>

<script>
export default {
  name: 'setting',
  methods: {
    showSetting() {
      this.$router.push('/mine/radar-set');
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  padding-top: 12px;

  .radar {
    margin-top: 8px;
  }

  p, h6 {
    margin: 0;
  }
}
</style>
